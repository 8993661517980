.container {
  background-color: black;
  height: 16vh;

  .logoContainer {
    max-height: 100%;
    // border: 1px solid red;
    img {
      max-height: 15vh;
      width: 100%;
      object-fit: cover;
    }
  }
}
