.singleImage {
    // max-height: 50vh;

  //   border: 1px solid red;
  overflow: hidden;
  border-radius: 12px;
  img {
    max-width: 100%;
    // max-height: 100%;
    object-fit: cover;
  }
}
