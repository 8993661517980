.menuContainer {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100vw;
  opacity: 0.97;
  background-color: black;
  overflow: hidden;
  .menuPage {
    // border: 1px solid red;
    height: 77%;
  }
}
